import { Modal } from '@/common/components/Modal';
import {
  DeleteIcon,
  DropdownWithCircle,
  EditIcon,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import { TabelWrapper } from '@/common/styled/Dialogue.Dumb';
import { getCurrentUserInfo } from '@/common/utils/utils';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  BotsP,
  Button,
  ButtonContainer,
  CancelButtonInput,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
  InputContent,
  isBlockedDomain,
  isValidEmail,
  TabContainer,
  Table,
  TableContainer,
  TabRow,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from './ManageUsers';
import {
  AgentCreateT,
  AgentDetailsT,
  AgentEditT,
  AgentToEditT,
  AgentUpdateT,
  allowOnlyAlpha,
  editingInitialAgent,
  initialAgent,
  useAgentCreate,
  useAgentDelete,
  useAgentUpdate,
  useAllAgents,
  useAllTeams,
} from './manageUsersApi';
import { Input } from '@/common/styled/Dialog.BotDetails.Dumb';
import Downshift from 'downshift';
import { User } from '../types.user';
import { Loader } from '@/common/components/Loader';
import { useSignal } from '@preact/signals-react';
import {
  usePermissions,
  usePermissionSignal,
} from '@/common/utils/auth-token-api-utils';
import Tooltip from 'react-tooltip-lite';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { TabHeader } from '@/Flows/analytics/logs';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { getJson, loginApiURL } from '@/common/utils/api-utils';
import { getLiveagentBots, useAdminData } from '@/LiveAgent/LivaAgentApi';
import { ProfileTabs } from '@/SidePanel/Sidebar';
import { getUsersAPI } from '../op.manageUsers';
type teamT = {
  id: string;
  name: string;
};
type roleT = {
  id: string;
  name: string;
  displayName: string;
};

function AgentDetails(props: any) {
  const workspace = props.match.params.workspacename;
  //   const isOwner = (agent: AgentDetailsT) => {
  //     return agent.id === agent.AccessibleWorkspaces[0].ownerId;
  //   };
  const [bots, setBots] = useState<BotsP[]>([]);
  const [mfaToggle, setMfaToggle] = useState<boolean>(false);
  useEffect(() => {
    getLiveagentBots(workspace)
      .then((res) => {
        const bots = res.map((b) => ({ id: b.id, name: b.name }));
        setBots(bots);
      })
      .catch((err) => console.log('Err::', err));
    getJson(loginApiURL(`/${workspace}/mFAActiveStatusInWS`))
      .then((res) => setMfaToggle(res.is_mfa_active))
      .catch((e) => {
        console.log(e);
      });
  }, []);
  type tab = 'Admins' | 'Agents' | 'Teams';
  const [tab, setTab] = useState<tab>('Agents');
  const { AllAgentsData, agentsFetching } = useAllAgents(workspace);
  const [createpopup, setCreatepopup] = useState<boolean>(false);
  const selectedLink$ = useSignal<string>('');
  const userLinks = `SHARED_INBOX_MODULE/SHARED_INBOX_USERS`;
  const readUserPermission = usePermissions(workspace, userLinks, 'READ');
  const writeUserPermission = usePermissions(workspace, userLinks, 'WRITE');
  const deleteUserPermission = usePermissions(workspace, userLinks, 'DELETE');
  console.log(AllAgentsData, 'DOING :: 3 :: PERMISSION RETURNED');
  const {adminData} = useAdminData(workspace)
  const {
    data: currentUser,
    isLoading: currentUserInfoLoading,
    isError: currUserInfoIsErr,
    error: currUserInfoErr,
  } = useQuery(`currentUser`, () => getCurrentUserInfo());
  const getAgentStatus = (id:number,role:string,adminData:User[])=>{
    const currentUser = adminData.find(ad=> ad.id === id)
    if(currentUser?.verified){
      return role
    } else{
      return "invited"
    }
  }
  const [roles, setRoles] = useState<roleT[]>([
    {
      id: '1',
      name: 'shared_inbox_admin',
      displayName: 'Shared inbox Admin',
    },
    {
      id: '2',
      name: 'shared_inbox_agent',
      displayName: 'Shared inbox Agent',
    },
  ]);
  const { AllTeamsData } = useAllTeams(workspace);
  const [teamsList, setTeamsList] = useState<teamT[]>([
    {
      id: 'teams_01',
      name: 'IT Support',
    },
    {
      id: 'teams_02',
      name: 'HR Support',
    },
    {
      id: 'teams_03',
      name: 'Live_chat',
    },
  ]);
  const checkRole = (role: string) => {
    console.log(role, 'sfasdfasd');
    switch (role) {
      case 'shared_inbox_admin':
        return 'Shared inbox Admin';
      case 'shared_inbox_agent':
        return 'Shared inbox Agent';
      default:
        return role;
    }
  };
  const [agentUpdatePopup, setAgentUpdatePopup] = useState<{
    agent: AgentToEditT;
    popup: boolean;
  }>({
    agent: editingInitialAgent,
    popup: false,
  });
  // console.log(agentUpdatePopup, currentUser, 'agentUpdatePopup');
  const [agentDeletePopup, setAgentDeletePopup] = useState<{
    agent: AgentDetailsT;
    popup: boolean;
  }>({
    agent: initialAgent,
    popup: false,
  });
  const [errMsg, setErrMsg] = useState<String>('');
  const { agentDelete } = useAgentDelete(
    () => {
      setAgentDeletePopup({
        agent: initialAgent,
        popup: false,
      });
    },
    () => {
      setAgentErr(true);
      setErrMsg('Error in deleting the agent');
    }
  );
  const removeAgent = (agent: AgentDetailsT) => {
    console.log('useAgentDeleteapicalled');
    const update: AgentUpdateT = {
      workspace: workspace,
      agentID: agent.id,
      body: {
        first_name: '',
        last_name: '',
        email: '',
        teams: [],
        role: '',
      },
    };
    agentDelete(update);
  };
  const { agentUpdate } = useAgentUpdate(
    () => {
      // cancel();
      console.log('updateDone');
    },
    () => {
      // setEditErr(true);
      setErrMsg('Error in editing the agent');
    }
  );
  const agentStatusUpdate = (agent: AgentToEditT) => {
    console.log('agentStatusUpdate');
    const update: AgentUpdateT =
      agent.role === 'super_admin'
        ? {
            workspace: workspace,
            agentID: agent.id,
            body: {
              // teams: agent.teams.map((te) => te.name),
              // role: agent.role,
              agent_status:
                agent.agent_status === 'Online' ? 'Offline' : 'Online',
            },
          }
        : {
            workspace: workspace,
            agentID: agent.id,
            body: {
              teams: agent.teams.map((te) => te.name),
              role: agent.role,
              agent_status:
                agent.agent_status === 'Online' ? 'Offline' : 'Online',
            },
          };
    agentUpdate(update);
  };
  const [agentErr, setAgentErr] = useState<boolean>(false);
  const openUpdate = (agent: AgentDetailsT) => {
    const findmyrole = AllAgentsData?.filter(
      (ag) => ag.auth_user_id === currentUser.id
    )[0]?.role;
    console.log(findmyrole, 'findmyrole');
    if (writeUserPermission.value) {
      if (findmyrole === 'super_admin') {
        return (
          agent.role === 'shared_inbox_agent' ||
          agent.role === 'shared_inbox_admin'
        );
      } else if (findmyrole === 'shared_inbox_admin') {
        return agent.role === 'shared_inbox_agent';
      } else {
        return false;
      }
      // : false
      // return true;
      // } else if (readUserPermission.value) {
      //   if (currentUser && agent.id === currentUser.id) {
      //     return true;
      //   } else {
      //     return false;
      //   }
    } else {
      return false;
    }
  };

  const getRole = (apiRole: string) => {
    switch (apiRole) {
      case 'shared_inbox_admin':
        return 'Shared inbox Admin';
      case 'shared_inbox_agent':
        return 'Shared inbox Agent';
      case 'super_admin':
        return 'Admin';
      // case 'last14days':
      // return "Chatbot Contributor";
      // case 'last14days':
      // return "Chatbot Admin";
      default:
        return apiRole;
    }
  };
  const availabilityPermission = (agent: AgentDetailsT) => {
    const findmyrole = AllAgentsData?.filter(
      (ag) => ag.auth_user_id === currentUser.id
    )[0]?.role;
    return findmyrole === 'shared_inbox_agent'
      ? agent.auth_user_id === currentUser.id
      : true;
  };
  const errComponent = agentErr ? (
    <React.Fragment>
      {errMsg}
      <Button
        primary
        onClick={() => {
          setAgentErr(false);
          setAgentDeletePopup({
            agent: initialAgent,
            popup: false,
          });
        }}
        type="button"
      >
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  if (agentsFetching) {
    return <Loader.PageLoader show={true} />;
  } else {
    return (
      <>
        <section className="tour_automation_canvas manage_user_new">
          <div className="header_box_shadow" />
          <div className="flow_canvas1">
            <div className="flex_canvas1">
              <div className="flex_menu1 flow_group_home">
                <div className="indent_dialog_left">
                  <div className="indent_left">
                    <div className="indent_title  mb-0">Manage Users</div>
                  </div>
                </div>
              </div>

              <div className="flex_side_menu1 intent_home flow_group_home">
                <div className="intent_dialog_right" />
                <ProfileTabs {...(props as any)} />
              </div>
            </div>
          </div>
        </section>
        <TabContainer className="manageUser_tab">
          <TabRow className="manageUser_tabrow">
            <TabHeader
              className={tab === 'Admins' ? 'active' : ''}
              onClick={() =>
                props.history.push(
                  `/workspace/${props.match.params.workspacename}/users`
                )
              }
            >
              Admins
            </TabHeader>
            <TabHeader
              className={tab === 'Agents' ? 'active' : ''}
              onClick={() => {
                setTab('Agents');
              }}
            >
              Agents
            </TabHeader>
            <TabHeader
              className={tab === 'Teams' ? 'active' : ''}
              onClick={() =>
                props.history.push(
                  `/workspace/${props.match.params.workspacename}/teams`
                )
              }
            >
              Teams
            </TabHeader>
            {writeUserPermission.value && (
              <div className="btn_width_wrapper manage_user_btn">
                {/* {users !== undefined &&
                noOfUsersAllowed &&
                users.length < noOfUsersAllowed && ( */}
                <Button
                  className="btn btn-primary"
                  onClick={() => setCreatepopup(true)}
                >
                  <span className="header_button">
                    <svg
                      height="512pt"
                      viewBox="0 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m512 412c0 11.046875-8.953125 20-20 20h-60v60c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-60h-60c-11.046875 0-20-8.953125-20-20s8.953125-20 20-20h60v-60c0-11.046875 8.953125-20 20-20s20 8.953125 20 20v60h60c11.046875 0 20 8.953125 20 20zm-160 80c0 11.046875-8.953125 20-20 20h-272c-33.085938 0-60-26.914062-60-60v-37c0-34.515625 14.804688-67.3125 40.613281-89.988281 20.894531-18.363281 60.910157-45.578125 125.238281-59.722657-35.15625-27.082031-57.851562-69.582031-57.851562-117.289062 0-81.605469 66.394531-148 148-148s148 66.394531 148 148-66.394531 148-148 148c-109.902344 0-168.777344 41.300781-188.984375 59.058594-17.167969 15.085937-27.015625 36.929687-27.015625 59.941406v37c0 11.027344 8.972656 20 20 20h272c11.046875 0 20 8.953125 20 20zm-96-236c59.550781 0 108-48.449219 108-108s-48.449219-108-108-108-108 48.449219-108 108 48.449219 108 108 108zm0 0" />
                    </svg>
                  </span>
                  <label>Create Agent</label>
                </Button>
                {/* )} */}
              </div>
            )}
          </TabRow>
        </TabContainer>

        <section className="cogni_cards sup_acc_user_details z-index-1-important manage_uesr_table">
          <div className="w-100 float-left">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="sup_acc_user_details_header">
                  <TabelWrapper>
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th size={2}>Email</Th>
                            <Th size={1.2}>First name</Th>
                            <Th size={1.2}>Last name</Th>
                            <Th size={1.5}>Teams</Th>
                            <Th size={2}>Role</Th>
                            <Th size={1.8}>Chat Sources</Th>
                            <Th size={1}>MFA</Th>
                            <Th size={1.5}>Status</Th>
                            <Th size={0.6}>Actions</Th>
                          </Tr>
                        </Thead>
                        {/* {console.log(agent,"AllAgentsData")} */}

                        <Tbody>
                          {currentUser &&
                          AllAgentsData && adminData &&
                          AllAgentsData.length > 0 ? (
                            AllAgentsData.map((agent: AgentDetailsT, index) => (
                              <Tr
                                key={index}
                                // onClick={
                                //   () =>
                                //     openUpdate(agent) &&
                                //     setAgentUpdatePopup({
                                //       agent: agent,
                                //       popup: true,
                                //     })
                                //   // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                // }
                              >
                                <Td
                                  size={2}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_user_container">
                                    <div className="td_user_img">
                                      {' '}
                                      <UserIconAnalyticsMenu />{' '}
                                    </div>
                                    <div className="td_user_mail">
                                      {agent.email}
                                    </div>
                                  </div>
                                </Td>
                                <Td
                                  size={1.2}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_header">
                                    {agent.first_name}
                                  </div>
                                </Td>
                                <Td
                                  size={1.2}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_header">
                                    {agent.last_name}
                                  </div>
                                </Td>
                                <Td
                                  size={1.5}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_user_container">
                                    <div className="position-relative d-flex flex-column">
                                      {agent.teams.map((team, index) => (
                                        <div
                                          className="p-1  d-flex align-items-center"
                                          key={index}
                                        >
                                          {team.name}
                                          {/* {
                                            AllTeamsData?.filter(
                                              (tea) => tea.id === team
                                            )[0]?.name
                                          } */}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </Td>
                                <Td
                                  size={2}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_user_container">
                                    <div className="position-relative d-flex flex-column">
                                      {getRole(agent.role)}
                                      {/* {
                                            roles.filter(
                                              (rols) => rols.name === agent.role
                                            )[0]?.displayName
                                          } */}
                                    </div>
                                  </div>
                                </Td>
                                <Td
                                  size={1.8}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_header">
                                    {agent.chat_sources.map((chat, index) => (
                                      <div
                                        key={index}
                                        className="p-1  d-flex align-items-center"
                                      >
                                        {chat.name}
                                      </div>
                                    ))}
                                  </div>
                                </Td>
                                <Td
                                  size={1}
                                  onClick={
                                    () =>
                                      openUpdate(agent) &&
                                      setAgentUpdatePopup({
                                        agent: agent,
                                        popup: true,
                                      })
                                    // currentUser.id !== agents.id && !isOwner(agents) && actions.editRole(user)
                                  }
                                >
                                  <div className="td_header">
                                    <div className="show_hide agent_page">
                                      <div className="switch_box box_1">
                                        <input
                                          type="checkbox"
                                          style={{ opacity: 0.5 }}
                                          className={`switch_1`}
                                          checked={mfaToggle}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Td>
                                <Td size={1.5}>
                                  <div className="td_header">
                                    <div className="show_hide agent_page">
                                      <div className="switch_box box_1">
                                        <input
                                          type="checkbox"
                                          style={{ opacity: 0.5 }}
                                          className={`switch_1`}
                                          checked={
                                            getAgentStatus(agent.auth_user_id,agent.agent_status,adminData) === 'Online'
                                          }
                                          onChange={() => {
                                            console.log('agentStatusUpdate');
                                            availabilityPermission(agent) && getAgentStatus(agent.auth_user_id,agent.agent_status,adminData) !== "invited" &&
                                              agentStatusUpdate(agent);
                                          }}
                                        />
                                      </div>
                                      <span className={getAgentStatus(agent.auth_user_id,agent.agent_status,adminData) === "invited" ? "td_user_invited":""}>
                                      {getAgentStatus(agent.auth_user_id,agent.agent_status,adminData)}
                                      </span>
                                    </div>

                                    {/* {
                                  <AgentActiveStatus
                                    user={agent}
                                    actions={{ ...actions }}
                                    isOwner={isOwner}
                                    currentUser={currentUser.id === agent.id}
                                  ></AgentActiveStatus>
                                } */}
                                  </div>
                                </Td>
                                <Td size={0.6}>
                                  {openUpdate(agent) && (
                                    //   && !isOwner
                                    <div className="list-view-icon td_header">
                                      <span
                                        className="td_editicon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAgentUpdatePopup({
                                            agent: agent,
                                            popup: true,
                                          });
                                        }}
                                      >
                                        {currentUser &&
                                          currentUser.id !==
                                            parseInt(agent.id) && (
                                            //   !isOwner &&
                                            <EditIcon />
                                          )}
                                      </span>
                                      {deleteUserPermission.value && (
                                        <span
                                          className="td_editicon"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setAgentDeletePopup({
                                              agent: agent,
                                              popup: true,
                                            });
                                          }}
                                        >
                                          <DeleteIcon />
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Td>
                              </Tr>
                            ))
                          ) : (
                            <div className="liveAgent_inbox_conversation_trash">
                              <p>No agent data</p>
                            </div>
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabelWrapper>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ) : (
          <div className="d-flex manage_user warning_box_container">
            <div className="d-flex warning_box">
              <div>
                <WarningAlertIcon />
              </div>
              <div>
                <p>
                  You don’t have access to this page. Contact your workspace
                  admin
                </p>
              </div>
            </div>
          </div>
        )} */}
        {createpopup && AllTeamsData && AllAgentsData && (
          <AgentCreate
            workspace={workspace}
            checkRole={checkRole}
            setCreatepopup={setCreatepopup}
            currentUser={currentUser}
            teamsList={AllTeamsData}
            AllAgentsData={AllAgentsData}
            roles={roles}
            callbacks={{
              completed: () => {
                setCreatepopup(false);
              },
              failed: (e) => e.message,
              cancel: () => {
                setCreatepopup(false);
              },
            }}
          />
        )}
        {agentUpdatePopup.popup && (
          <AgentEdit
            agentToEdit={agentUpdatePopup.agent}
            checkRole={checkRole}
            workspace={workspace}
            currentUser={currentUser}
            teamsList={AllTeamsData ? AllTeamsData : []}
            roles={roles}
            callbacks={{
              completed: () => {
                setAgentUpdatePopup({
                  ...agentUpdatePopup,
                  popup: false,
                });
                //   invalidateWsUsers();
                //   editRolesPopup.close();
              },
              failed: (e) => e.message,
              cancel: () => {
                setAgentUpdatePopup({
                  ...agentUpdatePopup,
                  popup: false,
                });
              },
            }}
            //   botlist={botlist_cbc(editRolesPopup.rolesPopup.user)}
            //   adminBotList={botlist_cbAdmin(editRolesPopup.rolesPopup.user)}
            //   rBACAcceess={mfaAllowed}
          ></AgentEdit>
        )}
        {agentDeletePopup.popup && agentDeletePopup.agent !== null && (
          <Modal
            className="invite_new_user delete"
            //   close={!rolesMutation.isLoading ? cancel : R.identity}
            //   showPopupLoader={agentUpdateLoading}
          >
            {errComponent || (
              <React.Fragment>
                <div className="manage_user_delete">
                  <h2>Are you sure to remove the agent? </h2>
                  <ButtonContainer className="invite_user_popup pb-0">
                    <Button
                      primary
                      onClick={() =>
                        setAgentDeletePopup({
                          agent: initialAgent,
                          popup: false,
                        })
                      }
                      type="button"
                    >
                      <label>No</label>
                    </Button>
                    <Button
                      onClick={() => removeAgent(agentDeletePopup.agent)}
                      type="button"
                    >
                      <label>Yes</label>
                    </Button>
                  </ButtonContainer>
                </div>
              </React.Fragment>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default AgentDetails;

const AgentEdit = ({
  agentToEdit,
  workspace,
  currentUser,
  callbacks: { cancel, failed, completed },
  teamsList,
  roles,
  checkRole,
}: AgentEditT) => {
  console.log(teamsList, agentToEdit, 'agentUpdatePopup');
  //   const { agentToEdit, workspace } = props;
  const [errMsg, setErrMsg] = useState<String>('');
  const [editingAgent, setEditingAgent] =
    useState<AgentToEditT>(editingInitialAgent);
  const onlyAgentRole = [
    {
      id: '2',
      name: 'shared_inbox_agent',
      displayName: 'Shared inbox Agent',
    },
  ];
  // const checkDuplicateEmail = (email: string) => {
  //   const [result, setResult] = useState<string>('');
  //   getJson(loginApiURL(`v2/has_access/${workspace}/${email}`)).then((res) =>
  //     setResult(res)
  //   );
  //   return result;
  // };
  const teamList = (teamID: any) => {
    const list = editingAgent.teams;
    const checkAvailable = list.filter((li) => li.id === teamID.id);
    console.log(
      teamID,
      editingAgent.teams,
      checkAvailable,
      'adfadfasdfas edit'
    );

    if (checkAvailable.length > 0) {
      const removedList = list.filter((li) => li.id !== teamID.id);
      setEditingAgent({
        ...editingAgent,
        teams: removedList,
      });
    } else {
      list.push(teamID);
      setEditingAgent({
        ...editingAgent,
        teams: list,
      });
    }
  };
  useEffect(() => {
    setEditingAgent(agentToEdit);
  }, []);
  const [filterDropDown, setFilterDropDown] = useState<boolean>(false);
  const [roleDropDown, setRoleDropDown] = useState<boolean>(false);
  const [editErr, setEditErr] = useState<boolean>(false);
  const { agentUpdate } = useAgentUpdate(
    () => {
      cancel();
    },
    () => {
      setEditErr(true);
      setErrMsg('Error in editing the agent');
    }
  );
  const editAgent = (agent: AgentToEditT) => {
    const update: AgentUpdateT = {
      workspace: workspace,
      agentID: agent.id,
      body: {
        // first_name: editingAgent.first_name,
        // last_name: editingAgent.last_name,
        teams: editingAgent.teams.map((te) => te.id),
        role: editingAgent.role,
        agent_status: editingAgent.agent_status,
      },
    };
    agentUpdate(update);
  };
  // const err = agentUpdateError
  const [emailValidation, setEmailValidation] = useState<{
    valid: boolean;
    message: any;
  }>({
    valid: true,
    message: null,
  });

  const errComponent = editErr ? (
    <React.Fragment>
      {errMsg}
      <Button
        primary
        onClick={() => {
          setEditErr(false);
          cancel();
        }}
        type="button"
      >
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;

  return (
    <Modal
      className="invite_new_user"
      //   close={!rolesMutation.isLoading ? cancel : R.identity}
      //   showPopupLoader={agentUpdateLoading}
    >
      {errComponent || (
        <React.Fragment>
          <h2>Edit Agent </h2>
          <div className="invite_new_user_box">
            <div className="account_popup_user_input automate">
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>First Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter first name"
                      value={editingAgent.first_name}
                      // onChange={(e: any) => {
                      //   setEditingAgent({
                      //     ...editingAgent,
                      //     first_name: allowOnlyAlpha(e.target.value),
                      //   });
                      // }}
                    />
                    <InputContent className="input_content">
                      First name
                    </InputContent>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Last Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter last name"
                      value={editingAgent.last_name}
                      // onChange={(e: any) => {
                      //   setEditingAgent({
                      //     ...editingAgent,
                      //     last_name: allowOnlyAlpha(e.target.value),
                      //   });
                      // }}
                    />
                    <InputContent className="input_content">
                      Last name
                    </InputContent>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Email Address</label> */}
                    <Input
                      type="email"
                      className="form-control"
                      // placeholder="Enter email id"
                      value={editingAgent.email}
                    />
                    <InputContent className="input_content invite_email">
                      Email ID
                    </InputContent>
                    {!emailValidation.valid && (
                      <p className="error_msg">{emailValidation.message}</p>
                    )}
                    {/* {checkDuplicateEmail(editingAgent.email) && (
                      <p className="error_msg">
                        user email is already part of your workspace as “user
                        role“
                      </p>
                    )} */}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Input
                      type="text"
                      className="form-control"
                      value={checkRole(editingAgent.role)}
                      onClick={() => setRoleDropDown(true)}
                      disabled={
                        editingAgent.role !== 'shared_inbox_admin' &&
                        editingAgent.role !== 'shared_inbox_agent'
                      }
                    />
                    <InputContent className="input_content">
                      Agent role
                    </InputContent>
                    <CancelButtonInput>
                      <DropdownWithCircle />
                    </CancelButtonInput>
                    {roleDropDown && (
                      <Downshift
                        isOpen={roleDropDown}
                        onOuterClick={() => setRoleDropDown(false)}
                      >
                        {() => (
                          <div className="automation_scroly mb-0">
                            <DropdownUl
                              style={{
                                marginTop: 48,
                                left: 8,
                                width: '95%',
                              }}
                            >
                              {roles && roles.length > 0 ? (
                                roles.map((val, i: number) => (
                                  <DropdownLi
                                    key={i}
                                    onClick={() => {
                                      setEditingAgent({
                                        ...editingAgent,
                                        role: val.name,
                                      });
                                    }}
                                  >
                                    <div className="dropdown_list">
                                      <label>
                                        <input
                                          type="radio"
                                          className="option-input radio"
                                          checked={
                                            editingAgent.role === val.name
                                          }
                                        />
                                      </label>
                                      {val.displayName}
                                    </div>
                                  </DropdownLi>
                                ))
                              ) : (
                                <p>No Team Information to display</p>
                              )}
                            </DropdownUl>
                          </div>
                        )}
                      </Downshift>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex ml-3">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Input
                      type="text"
                      className={
                        editingAgent.role === 'shared_inbox_admin'
                          ? 'form-control disable'
                          : 'form-control'
                      }
                      style={
                        editingAgent.role === 'shared_inbox_admin'
                          ? { opacity: '0.3' }
                          : {}
                      }
                      value={
                        editingAgent.role === 'shared_inbox_admin'
                          ? ''
                          : editingAgent.teams.length>0 ? editingAgent.teams.map((val) => val.name).join(', ') : null
                      }
                      onClick={() => setFilterDropDown(true)}
                      disabled={editingAgent.role !== 'shared_inbox_agent'}
                    />
                    <InputContent className="input_content">Team</InputContent>
                    {editingAgent.role === 'shared_inbox_admin' && (
                      <Tooltip
                        className="target customTip alert_message"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={
                          'Admin will have access to all the Teams by default.'
                        }
                        distance={5}
                      >
                        <WarningAlertIcon />
                      </Tooltip>
                    )}
                    <CancelButtonInput
                      style={
                        editingAgent.role === 'shared_inbox_admin'
                          ? { opacity: '0.3' }
                          : {}
                      }
                    >
                      <DropdownWithCircle />
                    </CancelButtonInput>
                    {filterDropDown && (
                      <Downshift
                        isOpen={filterDropDown}
                        onOuterClick={() => setFilterDropDown(false)}
                      >
                        {() => (
                          <div className="automation_scroly mb-0">
                            <DropdownUl
                              style={{
                                marginTop: 48,
                                left: 8,
                                width: '95%',
                              }}
                            >
                              {teamsList && teamsList.length > 0 ? (
                                teamsList
                                  .map((team) => ({
                                    id: team.id,
                                    name: team.name,
                                  }))
                                  .map(
                                    (
                                      teamVal: { id: string; name: string },
                                      i: number
                                    ) => (
                                      <DropdownLi
                                        key={i}
                                        onClick={() => {
                                          teamList(teamVal);
                                        }}
                                        className="manage_role_li"
                                      >
                                        <div className="dropdown_list">
                                          <label>
                                            {console.log(
                                              teamsList,
                                              teamVal,
                                              editingAgent.teams,
                                              editingAgent.teams.filter(
                                                (te) => te.id === teamVal.id
                                              ).length > 0,
                                              'editing team'
                                            )}
                                            <input
                                              type="radio"
                                              className="option-input radio"
                                              checked={
                                                editingAgent.teams.filter(
                                                  (te) => te.id === teamVal.id
                                                ).length > 0
                                                // .includes(
                                                // val.id
                                                // )
                                              }
                                            />
                                          </label>
                                          {teamVal.name}
                                        </div>
                                      </DropdownLi>
                                    )
                                  )
                              ) : (
                                <p>No Team Information to display</p>
                              )}
                            </DropdownUl>
                          </div>
                        )}
                      </Downshift>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ButtonContainer className="invite_user_popup pb-0">
              <Button primary onClick={cancel} type="button">
                <label>Cancel</label>
              </Button>
              <Button
                disabled={
                  !isValidEmail(editingAgent.email) ||
                  editingAgent.first_name === '' ||
                  editingAgent.last_name === '' ||
                  editingAgent.email === ''
                  // ||
                  // editingAgent.role === "shared_inbox_admin"
                }
                onClick={() => editAgent(editingAgent)}
                type="button"
                className={
                  !isValidEmail(editingAgent.email) ||
                  editingAgent.first_name === '' ||
                  editingAgent.last_name === '' ||
                  editingAgent.email === ''
                    ? // ||
                      // editingAgent.role_id.id === 0
                      'editor_btn_disabled btn_invite'
                    : 'btn_invite'
                }
              >
                <label>Update</label>
              </Button>
            </ButtonContainer>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};
const AgentCreate = ({
  workspace,
  setCreatepopup,
  callbacks: { cancel, failed, completed },
  currentUser,
  teamsList,
  roles,
  AllAgentsData,
  checkRole,
}: AgentCreateT) => {
  //   const { agentToEdit, workspace } = props;
  const [agentDetails, setAgentDetails] = useState<AgentDetailsT>(initialAgent);
  // ({
  //   id: '',
  //   first_name: '',
  //   last_name: '',
  //   email: '',
  //   teams: [],
  //   role:"",
  //   deleted: false,
  //   agent_status: '',
  //   chat_sources: [],
  // });
  // useEffect(() => {
  //   setEditingAgent(agentToEdit);
  // }, []);
  const [filterDropDown, setFilterDropDown] = useState<boolean>(false);
  const [roleDropDown, setRoleDropDown] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<String>('');
  const [loading, showLoading] = useState<boolean>(false);
  const [mailCheck, setMailCheck] = useState<boolean>(false);
  const onlyAgentRole = [
    {
      id: '2',
      name: 'shared_inbox_agent',
      displayName: 'Shared inbox Agent',
    },
  ];
  const checkrole = () => {
    const findmyrole = AllAgentsData?.filter(
      (ag) => ag.auth_user_id === currentUser.id
    )[0]?.role;
    return findmyrole === 'shared_inbox_admin' ? false : true;
  };
  console.log(checkrole(), 'checkrole');
  const [emailValidation, setEmailValidation] = useState<{
    valid: boolean;
    message: any;
  }>({
    valid: true,
    message: null,
  });
  const [result, setResult] = useState<boolean>(false);

  useEffect(() => {
    const promse = () =>
      getJson(
        loginApiURL(`/access/v2/has_access/${workspace}/${agentDetails.email}`)
      );
    promse()
      .then((res) => setResult(res.hasAccess))
      .catch((err) => {
        setMailCheck(true);
        console.log(err, 'err');
      });
  }, [agentDetails.email]);
  const teamList = (teamID: any) => {
    console.log(teamID, 'adfadfasdfas');
    const list = agentDetails.teams;
    const checkAvailable = list.filter((li) => li.id === teamID.id);
    if (checkAvailable.length > 0) {
      const removedList = list.filter((li) => li.id !== teamID.id);
      setAgentDetails({
        ...agentDetails,
        teams: removedList,
      });
    } else {
      list.push(teamID);
      setAgentDetails({
        ...agentDetails,
        teams: list,
      });
    }
  };
  const { agentCreate } = useAgentCreate(
    () => {
      setCreatepopup(false);
      showLoading(false);
    },
    () => {
      setCrateErr(true);
      setErrMsg('Error in agent creation');
      showLoading(false);
    }
  );
  const [createErr, setCrateErr] = useState<boolean>(false);
  console.log(createErr, 'createErr');
  const createAgent = () => {
    showLoading(true);
    const update: AgentUpdateT = {
      workspace: workspace,
      // agentID: agent.id,
      body: {
        first_name: agentDetails.first_name,
        last_name: agentDetails.last_name,
        email: agentDetails.email,
        teams: agentDetails.teams.map((team) => team.id),
        role: agentDetails.role,
        inviter: currentUser.id,
      },
    };
    console.log(update, 'createErr');

    agentCreate(update);
  };

  const saveDisable = () => {
    return (
      agentDetails.first_name === '' ||
      agentDetails.last_name === '' ||
      agentDetails.email === '' ||
      agentDetails.role === '' ||
      !isValidEmail(agentDetails.email) ||
      result
    );
  };
  const getuserRole = (email: string) => {
    const role = AllAgentsData.filter((agent) => agent.email === email)[0]
      ?.role;
    return checkRole(role);
  };
  const errComponent = createErr ? (
    <React.Fragment>
      {errMsg}
      <Button primary onClick={() => cancel()} type="button">
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  return (
    <Modal
      className="invite_new_user"
      close={!loading ? cancel : () => {}}
      showPopupLoader={loading}
    >
      {/* {!createErr ? ( */}
      <React.Fragment>
        <h2>Create Agent</h2>
        <div className="invite_new_user_box">
          <div className="account_popup_user_input automate">
            <div className="d-flex justify-content-around">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Input
                    type="text"
                    className="form-control"
                    // pattern="[A-Za-z]"
                    value={agentDetails.first_name}
                    onChange={(e: any) => {
                      setAgentDetails({
                        ...agentDetails,
                        first_name: allowOnlyAlpha(e.target.value),
                      });
                      // setFirstName(allowOnlyAlpha(e.target.value));
                    }}
                  />
                  <InputContent className="input_content">
                    First name
                  </InputContent>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Input
                    type="text"
                    className="form-control"
                    // pattern="[A-Za-z]"
                    value={agentDetails.last_name}
                    onChange={(e: any) => {
                      setAgentDetails({
                        ...agentDetails,
                        last_name: allowOnlyAlpha(e.target.value),
                      });
                    }}
                  />
                  <InputContent className="input_content">
                    Last name
                  </InputContent>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Input
                    type="email"
                    className={
                      agentDetails.email.length > 0
                        ? 'form-control have_value'
                        : 'form-control'
                    }
                    // placeholder="Enter email id"
                    value={agentDetails.email}
                    onChange={(e: any) => {
                      const email = e.target.value;
                      const formatIsValid = isValidEmail(agentDetails.email);
                      const emailIsSameAsLoggedInUser =
                        currentUser.email === email;
                      const isValid =
                        formatIsValid &&
                        !emailIsSameAsLoggedInUser &&
                        !isBlockedDomain(email);
                      setEmailValidation({
                        valid: isValid,
                        message: formatIsValid
                          ? emailIsSameAsLoggedInUser
                            ? "You can't invite yourself"
                            : 'Only corporate emails are allowed'
                          : 'Email is invalid',
                      });
                      setAgentDetails({
                        ...agentDetails,
                        email: e.target.value,
                      });
                    }}
                  />
                  <InputContent className="input_content invite_email">
                    Email ID
                  </InputContent>
                  {!emailValidation.valid && (
                    <p className="error_msg">{emailValidation.message}</p>
                  )}
                  {/* {console.log(getuserRole(agentDetails.email),"checkrole")} */}
                  {result && (
                    <p className="error_msg">
                      {`user email is already part of your workspace as ${getuserRole(
                        agentDetails.email
                      )}`}
                    </p>
                  )}
                  {/* {mailCheck && (
                    <p className="error_msg">issue in checking the email</p>
                  )} */}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Input
                    type="text"
                    className="form-control"
                    value={
                      roles.filter((role) => role.name === agentDetails.role)[0]
                        ?.displayName
                    }
                    onClick={() => setRoleDropDown(true)}
                  />
                  <InputContent className="input_content">
                    Agent Role
                  </InputContent>
                  <CancelButtonInput>
                    <DropdownWithCircle />
                  </CancelButtonInput>
                  {roleDropDown && (
                    <Downshift
                      isOpen={roleDropDown}
                      onOuterClick={() => setRoleDropDown(false)}
                    >
                      {() => (
                        <div className="automation_scroly mb-0">
                          <DropdownUl
                            style={{
                              marginTop: 47,
                              left: 8,
                              width: '95%',
                            }}
                          >
                            {roles && roles.length === 0 ? (
                              <p>No Team Information to display</p>
                            ) : checkrole() ? (
                              roles.map((val, i: number) => (
                                <DropdownLi
                                  key={i}
                                  onClick={() => {
                                    setAgentDetails({
                                      ...agentDetails,
                                      role: val.name,
                                    });
                                  }}
                                  className="mangae_role_li"
                                >
                                  <div className="dropdown_list">
                                    <label>
                                      <input
                                        type="radio"
                                        className="option-input radio"
                                        checked={agentDetails.role === val.name}
                                      />
                                    </label>
                                    {val.displayName}
                                  </div>
                                </DropdownLi>
                              ))
                            ) : (
                              onlyAgentRole.map((val, i: number) => (
                                <DropdownLi
                                  key={i}
                                  onClick={() => {
                                    setAgentDetails({
                                      ...agentDetails,
                                      role: val.name,
                                    });
                                  }}
                                  className="mangae_role_li"
                                >
                                  <div className="dropdown_list">
                                    <label>
                                      <input
                                        type="radio"
                                        className="option-input radio"
                                        checked={agentDetails.role === val.name}
                                      />
                                    </label>
                                    {val.displayName}
                                  </div>
                                </DropdownLi>
                              ))
                            )}
                          </DropdownUl>
                        </div>
                      )}
                    </Downshift>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex ml-3">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Input
                    type="text"
                    className={
                      agentDetails.role === 'shared_inbox_admin'
                        ? 'form-control disable'
                        : 'form-control'
                    }
                    style={
                      agentDetails.role === 'shared_inbox_admin'
                        ? { opacity: '0.3' }
                        : {}
                    }
                    value={agentDetails.teams.length >0 ? agentDetails.teams.map((val) => val.name).join(', '): null}
                    onClick={() => setFilterDropDown(true)}
                    disabled={agentDetails.role === 'shared_inbox_admin'}
                  />
                  <InputContent className="input_content">Team</InputContent>
                  {agentDetails.role === 'shared_inbox_admin' && (
                    <Tooltip
                      className="target customTip alert_message"
                      zIndex={10000}
                      arrowSize={8}
                      tagName="span"
                      content={
                        'Admin will have access to all the Teams by default.'
                      }
                      distance={5}
                    >
                      <WarningAlertIcon />
                    </Tooltip>
                  )}
                  <CancelButtonInput
                    style={
                      agentDetails.role === 'shared_inbox_admin'
                        ? { opacity: '0.3' }
                        : {}
                    }
                  >
                    <DropdownWithCircle />
                  </CancelButtonInput>
                  {filterDropDown && (
                    <Downshift
                      isOpen={filterDropDown}
                      onOuterClick={() => setFilterDropDown(false)}
                    >
                      {() => (
                        <div className="automation_scroly mb-0">
                          <DropdownUl
                            style={{
                              marginTop: 48,
                              left: 8,
                              width: '95%',
                            }}
                          >
                            {teamsList && teamsList.length > 0 ? (
                              teamsList.map(
                                (
                                  val: { id: string; name: string },
                                  i: number
                                ) => (
                                  <DropdownLi
                                    key={i}
                                    onClick={() => {
                                      teamList(val);
                                    }}
                                    className="mangae_role_li"
                                  >
                                    <div className="dropdown_list">
                                      <label>
                                        <input
                                          type="radio"
                                          className="option-input radio"
                                          checked={agentDetails.teams
                                            .map((te) => te.id)
                                            .includes(val.id)}
                                        />
                                      </label>
                                      {val.name}
                                    </div>
                                  </DropdownLi>
                                )
                              )
                            ) : (
                              <p>No Team Information to display</p>
                            )}
                          </DropdownUl>
                        </div>
                      )}
                    </Downshift>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ButtonContainer className="invite_user_popup pb-0">
            <Button primary onClick={() => setCreatepopup(false)} type="button">
              <label>Cancel</label>
            </Button>
            <Button
              disabled={saveDisable()}
              onClick={() => createAgent()}
              type="button"
              className={
                saveDisable() ? 'editor_btn_disabled btn_invite' : 'btn_invite'
              }
            >
              <label>Send Invite</label>
            </Button>
            {createErr && (
              <div className="group_alert_icon">
                <span className="list_flow_span alerticon">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={errMsg}
                    distance={5}
                  >
                    <WarningAlertIcon />
                  </Tooltip>
                </span>
              </div>
            )}
          </ButtonContainer>
        </div>
      </React.Fragment>
    </Modal>
  );
};

// function AgentActiveStatus({
//   user,
//   actions,
//   isOwner,
//   currentUser,
// }: {
//   user: User;
//   actions: UserActions;
//   isOwner: boolean;
//   currentUser: boolean;
// }) {
//   if (isOwner) {
//     return (
//       <div className="manage_user_status_section active_user">
//         <div className="show_hide">
//           <div className="switch_box box_1"></div>
//         </div>
//         <span>Workpace Owner</span>
//       </div>
//     );
//   } else if (currentUser) {
//     return (
//       <div className="manage_user_status_section active_user">
//         <div className="show_hide">
//           <div className="switch_box box_1"></div>
//         </div>
//         <span>Has workspace access</span>
//       </div>
//     );
//   } else {
//     return user.has_password ? (
//       user.AccessibleWorkspaces[0].WorkspaceAccess.valid ? (
//         <div className="manage_user_status_section active_user">
//           <div className="show_hide">
//             <div className="switch_box box_1">
//               <input
//                 type="checkbox"
//                 className={`switch_1 disabled`}
//                 checked={true}
//                 onChange={() => actions.disableUser(user)}
//               />
//             </div>
//           </div>
//           <span>Has workspace access</span>
//         </div>
//       ) : (
//         <div className="manage_user_status_section inactive_user">
//           <div className="show_hide">
//             <div className="switch_box box_1">
//               <input
//                 type="checkbox"
//                 className={`switch_1`}
//                 checked={false}
//                 onChange={() => actions.enableUser(user)}
//               />
//             </div>
//           </div>
//           <span>No Workspace Access</span>
//         </div>
//       )
//     ) : (
//       <span className="td_user_invited">Invited</span>
//     );
//   }
// }
