import { useEffect, useState } from 'react';
import {
  CancelButton,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  PromptButton,
  ScrollUl,
  Table,
  TableContainer,
  Td,
  TdContent,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import Downshift from 'downshift';

import {
  CloseIcon,
  DropdownWithCircle,
  SearchIcon,
} from '@/common/Icons/Icons';
import React from 'react';
import styled from 'styled-components';
import { Loader } from '@/common/components/Loader';
import { NodataContent } from '@/common/styled/Dialog.BotDetails.Dumb';
import { capitalizeFirstLetter } from '@/Flows/analytics/logs';
import { ResponsivePie } from '@nivo/pie';
import { CustomPieTooltip } from '@/Assistant/Dialogue/BotDetails';
import { Cell, Pie, PieChart } from 'recharts';
import WorldMap, { CountryContext } from 'react-svg-worldmap';
var InfiniteScroll = require('react-infinite-scroll-component');

export const DropdownWrapper = styled.div`
  width: 260px;
  float: left;
  position: absolute;
  border-radius: 6px;

  .search_click {
    position: relative;

    svg {
      width: 18px;
      height: 18px;
      color: #555555;
    }
  }
`;

export const DropdownUlWrapper = styled.div`
  width: 200px;
  position: relative;
  margin-top: 0px;
  right: -12px;
  cursor: pointer;
  padding: 0px;
  max-height: 212px;
  margin-bottom: 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  z-index: 2;
  margin-bottom: 0px;
  margin-top: 30px;
`;

export const DropdownUl = styled.div`
  width: ${(props: any) => (props.size === 'small' ? '200px' : 'auto')};
  position: ${(props) => (props.relative ? 'relative' : 'absolute')};
  margin-top: 0px;
  right: -12px;
  cursor: pointer;
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 212px;
  margin-bottom: 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  z-index: 2;
  margin-bottom: 0px;
  margin-top: 30px;
`;

export const DropdownLi = styled.div`
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  height: 36px;
  background: #fff;
  cursor: pointer !important;
  color: #355d8b;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  :hover {
    background: #ddfeef;
    color: #333;
    border-bottom: 1px solid #ddfeef;
  }

  .liveAt_user_select {
    .option-input {
      width: 16px;
      height: 16px;
      border: 1px solid #d8d8d8;
      top: 2px;
    }
  }

  .liveAt_user_name{
    padding-top: 3px;
  }
`;

export const DropdownUlForLogs = styled.div`
    width: 200px;
    position: relative;
    margin-top: 0px;
    right: -12px;
    cursor: pointer;
    padding: 0px;
    max-height: 212px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
    margin-top: 30px;
    }
`;

export const DropdwonLogSearch = (props: any) => {
  const { filterDropDown } = props;

  const [inputFocus, setInputFocus] = useState<boolean>(false);

  return (
    <DropdownWrapper className={props.className}>
      {filterDropDown && (
        <Downshift
          isOpen={filterDropDown}
          onOuterClick={() => props.closeFilterpopup()}
        >
          {() => (
            <span className="logs_email_loader">
              <DropdownUlWrapper
                style={{
                  minhHight: 200,
                  overflow: 'auto',
                  width: '100%',
                  marginTop: 0,
                  right: 0,
                  top: 18,
                  background: '#fff',
                  border: '1px solid #D9E2EB',
                }}
              >
                {!inputFocus ? (
                  <span className="search_icon_svg lat_chat">
                    <input
                      type="search"
                      placeholder="Search"
                      data-testid="INPUT-SMALL-BOX"
                      value=""
                      onFocus={() => setInputFocus(true)}
                    />
                    <SearchIcon />
                  </span>
                ) : (
                  <span className="search_click lat_chat">
                    <input
                      className="search_click"
                      type="search"
                      placeholder="Search"
                      data-testid="INPUT-BIG-BOX"
                      autoFocus={true}
                      onChange={(e) => {
                        props.settingSearch(e);
                      }}
                    />
                    <span
                      data-testid="BIG-INPUT-CLOSE-SPAN"
                      onClick={() => {
                        setInputFocus(false);
                        props.setSearchWord("")
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </span>
                )}
                <DropdownUl
                  style={{
                    marginTop: 0,
                    right: 0,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {props.list &&
                    props.list.map(
                      (val: { id: string; name: string }, i: number) => (
                        <DropdownLi
                          key={i}
                          onClick={() => {
                            if (props.value !== val.id) {
                              if ((val.name as any).date == 'custom') {
                                props.showCustomDatePicker();
                              }
                              props.onClick(val.id);
                              //   setOpen(false);
                              props.closeFilterpopup();
                            } else {
                              props.onClick('');
                              props.closeFilterpopup();
                            }
                          }}
                        >
                          <div className="liveAt_user_select">
                            <input
                              type="radio"
                              className="option-input radio"
                              checked={val.id === props.value}
                            />
                          </div>
                          <div className="liveAt_user_name">{val.name}</div>
                        </DropdownLi>
                      )
                    )}
                </DropdownUl>
              </DropdownUlWrapper>
            </span>
          )}
        </Downshift>
      )}
      {/* </InputContainer> */}
    </DropdownWrapper>
  );
};

export const DropdwonWithDebounce = (props: any) => {
  console.log(props, 'dropdownProps');
  // const [open, setOpen] = useState(false);

  const [inputFocus, setInputFocus] = useState<boolean>(false);
  return (
    <DropdownWrapper className={props.className}>
      {props.filterDropDown && (
        <Downshift
          isOpen={props.filterDropDown}
          onOuterClick={() => {
            props.closeFilterpopup();
            props.onBlur();
            props.loader();
          }}
        >
          {() => (
            <span className="logs_email_loader">
              <DropdownUlForLogs
                id="userEmail"
                style={{
                  minhHight: 200,
                  overflow: 'auto',
                  width: '100%',
                  marginTop: 0,
                  right: 0,
                  top: 18,
                  background: '#fff',
                  border: '1px solid #D9E2EB',
                }}
              >
                {!inputFocus ? (
                  <span className="search_icon_svg lat_chat">
                    <input
                      type="search"
                      placeholder="Search"
                      data-testid="INPUT-SMALL-BOX"
                      value=""
                      onFocus={() => setInputFocus(true)}
                    />
                    <SearchIcon />
                  </span>
                ) : (
                  <span className="search_click lat_chat">
                    <input
                      className="search_click"
                      type="search"
                      placeholder="Search"
                      data-testid="INPUT-BIG-BOX"
                      autoFocus={true}
                      onChange={(e) => {
                        console.log(e.target.value, 'debouncedOnChange search');
                        props.onChange(e.target.value);
                      }}
                    />
                    <span
                      data-testid="BIG-INPUT-CLOSE-SPAN"
                      onClick={() => {
                        setInputFocus(false);
                        props.onChange("")
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </span>
                )}
                {props.showLoader == 'search' ? (
                  <Loader.PopupLoader show className={'email_search_loader'} />
                ) : (
                  <InfiniteScroll
                    dataLength={props.list.length} //This is important field to render the next data
                    next={props.fetchData}
                    hasMore={props.list.length < props.toatlCount}
                    loader={<></>}
                    // endMessage={<p>No more data to load.</p>}
                    scrollableTarget="userEmail"
                    // scrollThreshold={1}
                    style={{ overflow: 'hidden' }}
                    initialScrollY={0}
                  >
                    {/* <DropdownUl style={{ marginTop: 0, right: 0, width: '100%', position: 'relative' }}> */}
                    {!props.showNoDataFound ? (
                      props.list.map(
                        (val: { label: string; value: string }, i: number) => (
                          <DropdownLi
                            style={{ padding: '0px !important' }}
                            key={i}
                            onClick={() => {
                              if (props.value !== val.value) {
                                if ((val.value as any).date == 'custom') {
                                  props.showCustomDatePicker();
                                }
                                props.onClick(val.value);
                                props.closeFilterpopup();
                                props.onBlur();
                              } else {
                                props.onClick('');
                                props.closeFilterpopup();
                                props.onBlur();
                              }
                            }}
                          >
                            <div className="liveAt_user_select">
                              <input
                                type="radio"
                                className="option-input radio"
                                checked={val.value === props.value}
                              />
                            </div>
                            {console.log('header', props.header)}
                            <div className="liveAt_user_name">
                              {props.header === 'ChatID' &&
                              val.label !== 'All ChatIDs'
                                ? `#${
                                    parseInt(val.label) < 10
                                      ? '00' + val.label
                                      : parseInt(val.label) < 100
                                      ? '0' + val.label
                                      : val.label
                                  }`
                                : val.label}
                            </div>
                          </DropdownLi>
                        )
                      )
                    ) : (
                      <DropdownLi>No search results to show</DropdownLi>
                    )}
                    {props.showLoader == 'scroll' && (
                      <Loader.PopupLoader
                        show
                        className={'email_search_loader'}
                      />
                    )}
                    {/* </DropdownUl> */}
                  </InfiniteScroll>
                )}
              </DropdownUlForLogs>
            </span>
          )}
        </Downshift>
      )}
      {/* </InputContainer> */}
    </DropdownWrapper>
  );
};

export type chatAssignmentT = {
  AssignedCount: number;
  UnassignedCount: number;
  AssignedNotReplied: number;
};
export type ChatStausT = {
  Resolved: number;
  Open: number;
  Queue: number;
  Closed: number;
  TotalCount: number;
};
type dataT = {
  value: number;
  id: string;
  label: string;
};
export function DashboardPieChart(props: {
  querySummary: chatAssignmentT | ChatStausT;
  keys: string[];
}) {
  console.log(props, 'DashboardPieChart');
  const { querySummary, keys } = props;

  function sum(obj: any) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] || 0),
      0
    );
  }

  const total = sum(querySummary);

  const data = keys.reduce((accum: dataT[], key) => {
    console.log(querySummary[`${key}`], key, querySummary[key], 'chlkahldak');

    if (querySummary[`${key}`]) {
      if (key === 'AssignedCount') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: 'Assigned chats',
          },
        ];
      } else if (key === 'AssignedNotReplied') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Assigned and not replied`,
          },
        ];
      } else if (key === 'UnassignedCount') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Unassigned`,
          },
        ];
      } else if (key === 'Closed') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Closed`,
          },
        ];
      } else if (key === 'Open') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Open`,
          },
        ];
      } else if (key === 'Queue') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Waiting in queue`,
          },
        ];
      } else if (key === 'Resolved') {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: `Resolved`,
          },
        ];
      } else {
        return [
          ...accum,
          {
            value: querySummary[key],
            id: key,
            label: capitalizeFirstLetter(key),
          },
        ];
      }
    }
    return accum;
  }, []);
  return (
    <div className="pie_chat">
      {total === 0 ? (
        <div className="pie_chart_img liveAgent">
          <NodataContent>No data available</NodataContent>
        </div>
      ) : (
        <PieCharts
          data={data}
          colors={{
            AssignedCount: '#76AEF6',
            UnassignedCount: '#FF7462',
            AssignedNotReplied: '#7AE3DC',
            Resolved: '#7AE3DC',
            Closed: '#FF7462',
            Open: '#76AEF6',
            Queue: '#F2A355',
          }}
        />
      )}
    </div>
  );
}

function PieCharts(props: {
  data: {
    value: number;
    id: string;
    label: string;
  }[];
  colors: { [key: string]: string };
}) {
  function returnColors(key: string) {
    console.log(key,'keaklhfad')
    switch (key) {
      case 'AssignedNotReplied':
        return "url(#colorUv)";
      case 'UnassignedCount':
        return "url(#redGrad)";
      case 'AssignedCount':
        return "url(#blueGrad)";
      case "overdue":
        return "url(#orangeGrad)";
      case 'Open':
        return "url(#blueGrad)";
      case 'Queue':
        return "url(#orangeGrad)";
      case 'Closed':
        return "url(#colorUv)";
      case "Resolved":
        return "url(#colorUv)";
    }
  }
  const total = props.data.reduce((acc, val) => (acc += val.value), 0);
  const modifiedData = props.data.map((dat) => ({
    ...dat,
    colors: returnColors(dat.id),
  }));
  console.log(modifiedData, 'DashboardPieChartpie');
  return (
    <div
      className="analytics_charts_left_img pie-chart"
      style={{ marginTop: 0 }}
    >
      <div className="pie_chart_img user_queries toolTip">
        <PieChart width={200} height={200}>
          <defs>
            <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#B7FFFA" />
              <stop offset="100%" stopColor="#7AE3DC"/>
            </linearGradient>
            <linearGradient id="blueGrad" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#4E90E5" />
              <stop offset="100%" stopColor="#76AEF6"/>
            </linearGradient>
            <linearGradient id="orangeGrad" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#D5883D" />
              <stop offset="100%" stopColor="#F2A355"/>
            </linearGradient>
            <linearGradient id="redGrad" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#E25C4B" />
              <stop offset="100%" stopColor="#FF7462"/>
            </linearGradient>
          </defs>
          <Pie
            data={modifiedData}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            innerRadius={80}
            fill="#8884d8"
            startAngle={-270}
            endAngle={-630}
          >
            {modifiedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`${entry.colors}`} />
            ))}
          </Pie>
          {/* <ChartTip content={<CustomPieTooltipForMostFrequentPieChart />} /> */}
          {/* <Legend /> */}
        </PieChart>
        <span className="pie_chart_center user_queries">
          <p style={{ color: '#37BCB3' }}>{total ? Math.round(total) : 0}</p>
          {/* <p style={{ color: '#37BCB3' }}>Auto Resolved</p>
          <p>
              <img src={SatisfiedEmoji} alt="satisfied" />
            </p> */}
        </span>
      </div>
    </div>
  );
}

export default function MapChart(props:{
  data:any[]
}) {
  const{data} = props
  console.log(data,'working');

  const stylingFunction = ({
    countryValue,
    minValue,
    maxValue,
    countryName,
    color,
  }:CountryContext) => {
    console.log(countryName,countryValue,'countryNamecheck')
    // cosnt checkAvailable = 
    const calculatedValue =
      typeof countryValue === 'string' ? minValue : countryValue;
    const opacityLevel =
      calculatedValue !== undefined
        ? 0.1 + (1.5 * (calculatedValue - minValue)) / (maxValue - minValue)
        : 1;
    return {
      fill: countryValue ? '#4f87d0' : '#dddbdb',
      fillOpacity: opacityLevel,
      stroke: '#000',
      strokeWidth: 1,
      strokeOpacity: 0.2,
      cursor: 'pointer',
    };
  };

  const getLocalizedText = ({
    countryValue,
    minValue,
    maxValue,
    countryName,
    color,
  }:CountryContext) => {
    console.log(countryName, countryValue,"local");
    // return country ? `${country} : ${countryValue}` : '';
    return countryName ? `${countryName} : ${countryValue}` : '';
  };

  return (
    // <div>
      <WorldMap
        color="red"
        // title="Top 10 Populous Countries"
        value-suffix="people"
        size={800}
        data={data}
        styleFunction={stylingFunction}
        tooltipTextFunction={getLocalizedText}
        tooltipBgColor="#fff"
        tooltipTextColor="#000"
      />
    // </div>
  );
}
