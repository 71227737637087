import {
  ActionT,
  BotsLA,
  PageStateLive,
  Sessions,
  VIEW,
  currentTab,
} from './LiveagentMockFile';
import {
  DownArrowCircle,
  LAEmptyIcon,
  NewTrymeIcon,
  SearchIcon,
  SlackIconCC,
  TeamsIcon,
  TrymeIcon,
  UserIconAnalytics,
  UserIconAnalyticsMenu,
  UserTriggerIcon,
  WidgetIcon,
} from '@/common/Icons/Icons';
import {
  ClosedSession,
  MentionSession,
  OpenSession,
} from './OpenClosedSessions';
import Downshift from 'downshift';
import { convertToMDText, formatAMPM, formatDateInCard } from '@/common/utils/utils';
import React, { useEffect, useState } from 'react';
import {
  conversation,
  useAllSession,
  useMySessions,
  useQueusStatus,
  useTrashedStatus,
  useUserData,
} from './LivaAgentApi';

import AssistantMd from '@skitter/assistant_md';
import { ChatUserInfo, WaitingTime } from './ChatUser';
import { Input } from 'rsuite';
import { Loader } from '@/common/components/Loader';
import { UserApi } from '@/DialogEditor/Canvas/Components/GetStarted';
import { differenceInMinutes } from 'date-fns';
import { QueryClient, useQueryClient } from 'react-query';

import emptyImg from '../src/image/empty_inbox.png';
var InfiniteScroll = require('react-infinite-scroll-component');
export type SessionChatP = {
  pageState: PageStateLive;
  setPageState: React.Dispatch<React.SetStateAction<PageStateLive>>;
  selectedBotID: string;
  currentTab: currentTab;
  selectConvo: (convo: string) => void;
  selectMultiConvo: (convo: string) => void;
  convoRestore: (convo: string) => void;
  // setConvData={setConvData}
  bots: BotsLA[];
  workspacename: string;
  user?: UserApi;
  linkSelect: (selectedBotID: string, sessionID: number, tab: string) => void;
  status: string;
  tabId: string;
  convoDelete: (convo: string) => void;
  closeConvo: boolean;
  changeURL: () => void;
  checkChatID: (chatID: number, sessionValue: Sessions[]) => boolean;
  teamData: BotsLA[];
};

export function LiveAgentInbox(props: SessionChatP) {
  const tabLoc = props.status;
  // const { pageState, setPageState } = props;x§x§
  const currentTab = props.currentTab.includes('#') ? 'All' : props.currentTab;
  const { user } = useUserData();
  function renderTabUI() {
    switch (currentTab) {
      case 'All':
        return <AllSession {...props} user={user} />;
      case 'Queue':
        return <QueuedSession {...props} />;
      case 'My':
        if (user) {
          return <MySession props={props} user={user} />;
        }
        return null;
      case 'Open':
        return <OpenSession {...props} />;
      case 'Closed':
        return <ClosedSession {...props} />;
      case 'Trash':
        return <InboxTrash {...props} />;
      case 'Mention':
        if (user) {
          return <MentionSession props={props} user={user} />;
        }
        return null;
    }
  }
  return renderTabUI();
}

export const platformIcon = (platform: string) => {
  switch (platform) {
    case 'slack':
      return <SlackIconCC />;
    case 'teams':
      return <TeamsIcon />;
    case 'widget':
      return <WidgetIcon />;
    case 'tryme':
      return <TrymeIcon />;
    default:
      return <UserTriggerIcon />;
  }
};
export function AllSession(props: SessionChatP) {
  console.log(props, 'AllSession');
  const { pageState, setPageState, selectedBotID, bots } = props;
  const tabId = props.tabId;
  const queryClient = useQueryClient();
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { AllData, isAllSessionsFetched } = useAllSession(
    props.workspacename,
    selectedBotID,
    sortValue && sortValue,
    pageNumber,
    () => setLoading(false),
    props.user?.id
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [AllSessionData, setAllSessionData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && AllData) {
      setAllSessionData(AllData.sessions.result);
      setTotalCount(AllData.sessions.total_count);
    } else if (AllData) {
      setAllSessionData([...AllSessionData, ...AllData.sessions.result]);
      setTotalCount(AllData.sessions.total_count);
    }
  }, [AllData]);
  // const AllSessionData = AllData?.sessions.result;
  const [searchWord, setSearchWord] = useState<string>('');
  const sessionData =
    searchWord !== '' && AllSessionData
      ? AllSessionData.filter((ses) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : AllSessionData;

  const idAvailable = sessionData
    ? props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convDatas = conversation(tabId, sessionData, idAvailable);
  console.log('convDatas', sessionData, AllSessionData, convDatas);

  useEffect(() => {
    if (convDatas.session_status !== 'Closed') {
      setPageState({
        action: ActionT.SELECT,
        data: [convDatas.id],
      });
    } else {
      setPageState({
        action: ActionT.MOVETRASH,
        data: [convDatas.id],
      });
    }
  }, [convDatas]);

  console.log(pageState.action === ActionT.VIEW, pageState, 'pagestateall');

  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      setMultiRadioSelect(false);
      if (convDatas.session_status !== 'Closed') {
        setPageState({
          action: ActionT.SELECT,
          data: [convDatas.id],
        });
      } else {
        setPageState({
          action: ActionT.MOVETRASH,
          data: [convDatas.id],
        });
      }
    }

    if (pageState.action === ActionT.SELECT) {
      setMultiRadioSelect(false);
    }
  }, [pageState]);

  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);

  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MULTITRASH,
        data: allIDs,
      });
    }
  };

  console.log(sessionData, pageState, tabId, 'multiRadioSelect');
  // if (!isAllSessionsFetched) {
  //   return <Loader.PageLoader show={true} />;
  // } else {
  return (
    <>
      {/* liveagent inobx all */}
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <input
                  type="radio"
                  onClick={() => {
                    multiSelect(sessionData);
                    // setMultiRadioSelect(true);
                  }}
                  checked={multiRadioSelect}
                />
                <div className="liveAt_newest_dropdown">
                  <Input
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                    className="liveAt_mail_filter"
                  />
                  <div
                    className="arrow_up_form"
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('allSession');
                                }}
                                className={
                                  data === sortValue ? 'mail_sort_active' : ''
                                }
                              >
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                  value={searchWord}
                  // className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                // loader={<p>Loading...</p>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData.length > 0 &&
                  sessionData.map((data, index) => (
                    <div
                      className={
                        convDatas.chat_id === data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MULTITRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                          <sub></sub>
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MULTITRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.selectMultiConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MULTITRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.linkSelect(
                            props.selectedBotID,
                            data.chat_id,
                            'all'
                          );
                          setPageState({
                            action: ActionT.SELECT,
                            data: [data.id],
                          });
                          // setState({
                          //   ...state,
                          //   convData: data,
                          // });
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        {data.session_status === 'InProgress' ? (
                          <>
                            <div className="flex">
                              <p>Connected with:</p>
                              <span>
                                <div>
                                  <UserIconAnalyticsMenu />
                                </div>
                                <h6>
                                  {data.assigned_agent
                                    ? data.assigned_agent.first_name +
                                      data.assigned_agent.last_name
                                    : 'Unassigned'}
                                </h6>
                              </span>
                            </div>
                            <div className="last_message">
                              <AssistantMd
                                mdText={convertToMDText(data.custom_message)}
                              />
                            </div>
                          </>
                        ) : data.session_status === 'Queued' ? (
                          <>
                            <p className="flex">
                              Waiting since:
                              {/* <span>{waitingTime(data.start_time)}</span> */}
                              <WaitingTime startTime={data.start_time + 'Z'} />
                            </p>
                            <p>
                              <AssistantMd
                                mdText={convertToMDText(data.custom_message)}
                              />
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="flex">
                              <p>Connected with:</p>
                              <span>
                                <UserIconAnalyticsMenu />
                                <h6>
                                  {data.assigned_agent
                                    ? data.assigned_agent.first_name +
                                      data.assigned_agent.last_name
                                    : 'Unassigned'}
                                </h6>
                              </span>
                            </div>
                            <p>
                              <AssistantMd
                                mdText={convertToMDText(data.custom_message)}
                              />
                            </p>
                          </>
                        )}
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          <p>
                            {data.session_status === 'InProgress'
                              ? 'Open'
                              : data.session_status}
                          </p>
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                    // </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>

          <ChatUserInfo
            data={convDatas ? convDatas.id : sessionData[0].chat_id.toString()}
            workspace={props.workspacename}
            botName={botName}
            closeConvo={props.closeConvo}
          />
        </section>
      ) : (
        // Empty inbox UI
        <section className="liveAgent_inbox_section empty inbox_all_pages">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <input type="radio" disabled />
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                  />
                  <div className="arrow_up_form">
                    <DownArrowCircle />
                  </div>
                </div>
              </div>
            </div>
            <div className="liveAt_inbox_search empty_page">
              <span className="search_click">
                <input
                 value={searchWord}
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                <SearchIcon />
              </span>
            </div>
            <div className="liveAt_inbox_mail_list_container"></div>
          </div>
          <div className="liveAgent_inbox_conversation_container empty">
            <div className="liveAt_conversation_title flex"></div>
            <div className="liveAgent_inbox_conversation_box">
              <div className="liveAgent_inbox_conversation_empty">
                <img style={{ width: '50%' }} src={emptyImg} alt="empty" />
                <p>Your inbox is empty.</p>
              </div>
            </div>
          </div>
          <div className="liveAgent_inbox_userInfo">
            <div className="liveAgent_empty_userinfo flex">
              <h4>User Info</h4>
              <UserIconAnalytics />
              <h5>No user in queue</h5>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
// }

export function QueuedSession(props: SessionChatP) {
  const { pageState, setPageState, bots } = props;
  const tabId = props.tabId;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { queueSessionData } = useQueusStatus(
    props.workspacename,
    'Queued',
    props.selectedBotID,
    sortValue,
    pageNumber,
    () => setLoading(false)
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [queuedSessionData, setQueuedSessionData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && queueSessionData) {
      setQueuedSessionData(queueSessionData.sessions.result);
      setTotalCount(queueSessionData.sessions.total_count);
    } else if (queueSessionData) {
      setQueuedSessionData([
        ...queuedSessionData,
        ...queueSessionData.sessions.result,
      ]);
      setTotalCount(queueSessionData.sessions.total_count);
    }
  }, [queueSessionData]);
  const queryClient = useQueryClient();
  const [searchWord, setSearchWord] = useState<string>('');
  const sessionData =
    searchWord !== ''
      ? queuedSessionData &&
        queuedSessionData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : queuedSessionData && queuedSessionData;
  const idAvailable = sessionData
    ? props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convDatas = conversation(tabId, sessionData, idAvailable);
  useEffect(() => {
    setPageState({
      action: ActionT.SELECT,
      data: [convDatas.id],
    });
  }, [convDatas]);
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      setMultiRadioSelect(false);
      setPageState({
        action: ActionT.SELECT,
        data: [convDatas.id],
      });
    } else if (pageState.action === ActionT.MOVETRASH) {
      setMultiRadioSelect(true);
    } else if (pageState.action === ActionT.SELECT) {
      setMultiRadioSelect(false);
    }
  }, [pageState]);

  const botName = (id: string) => {
    // REVIEW :: can this be replaced with find wihich has better types and handle type errors accordingly
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);

  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MOVETRASH,
        data: allIDs,
      });
    }
  };
  return (
    <div>
      {/* liveagent inobx chat in queue */}
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <input
                  type="radio"
                  onClick={() => multiSelect(sessionData)}
                  checked={multiRadioSelect}
                />
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                  />
                  <div
                    className="arrow_up_form"
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('queueStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                 value={searchWord}
                  // className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      className={
                        convDatas.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.selectConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MOVETRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.linkSelect(
                            props.selectedBotID,
                            data.chat_id,
                            'queued-sessions'
                          );
                          setPageState({
                            action: ActionT.SELECT,
                            data: [data.id],
                          });
                          // setConvData(data)
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <p className="flex">
                          Waiting since:{' '}
                          {/* <span>{waitingTime(data.start_time)}</span> */}
                          <WaitingTime startTime={data.start_time + 'Z'} />
                        </p>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          {/* <p>Queue</p> */}
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convDatas.id}
            workspace={props.workspacename}
            botName={botName}
            closeConvo={props.closeConvo}
          />
        </section>
      ) : (
        <EmptyContainer searchWord={searchWord} setSearchWord={setSearchWord}/>
      )}
    </div>
  );
}

export function MySession(props: { props: SessionChatP; user: UserApi }) {
  const { pageState, setPageState, bots, workspacename, selectedBotID } =
    props.props;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const tabId = props.props.tabId;
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { mySessionData } = useMySessions(
    workspacename,
    props.user?.id,
    selectedBotID,
    sortValue,
    pageNumber,
    () => setLoading(false)
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [agentSessionData, setAgentSessionData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && mySessionData) {
      setAgentSessionData(mySessionData.sessions.result);
      setTotalCount(mySessionData.sessions.total_count);
    } else if (mySessionData) {
      setAgentSessionData([
        ...agentSessionData,
        ...mySessionData.sessions.result,
      ]);
      setTotalCount(mySessionData.sessions.total_count);
    }
  }, [mySessionData]);
  const queryClient = useQueryClient();
  const [searchWord, setSearchWord] = useState<string>('');
  // const [convData, setConvData] = useState<Sessions>(initialSessionData);

  // const [allConvId, setAllConvId] = useState<string>('');
  const sessionData =
    searchWord !== ''
      ? agentSessionData &&
        agentSessionData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : agentSessionData && agentSessionData;

  const idAvailable = sessionData
    ? props.props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convData = conversation(tabId, sessionData, idAvailable);
  useEffect(() => {
    setPageState({
      action: ActionT.SELECT,
      data: [convData.id],
    });
  }, [convData]);
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      setMultiRadioSelect(false);
      setPageState({
        action: ActionT.SELECT,
        data: [convData.id],
      });
    } else if (pageState.action === ActionT.MOVETRASH) {
      setMultiRadioSelect(true);
    } else if (pageState.action === ActionT.SELECT) {
      setMultiRadioSelect(false);
    }
  }, [pageState]);

  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);

  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MOVETRASH,
        data: allIDs,
      });
    }
  };
  return (
    <div>
      {/* liveagent inobx my chat */}
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <input
                  type="radio"
                  onClick={() => multiSelect(sessionData)}
                  checked={multiRadioSelect}
                />
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                  />
                  <div
                    className="arrow_up_form"
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('myStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                 value={searchWord}
                  // className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      className={
                        convData.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.props.selectConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MOVETRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.props.linkSelect(
                            selectedBotID,
                            data.chat_id,
                            'my-session'
                          );
                          setPageState({
                            action: ActionT.SELECT,
                            data: [data.id],
                          });
                          // setConvData(data);
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <div className="flex">
                          <p>Connected with:</p>
                          <span>
                            <div>
                              <UserIconAnalyticsMenu />
                            </div>
                            <h6>
                              {data.assigned_agent
                                ? data.assigned_agent.first_name +
                                  data.assigned_agent.last_name
                                : 'Unassigned'}
                            </h6>
                          </span>
                        </div>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          {/* <p>{data.session_status}</p> */}
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convData.id}
            workspace={workspacename}
            botName={botName}
            closeConvo={props.props.closeConvo}
          />
        </section>
      ) : (
        <EmptyContainer searchWord={searchWord} setSearchWord={setSearchWord} />
      )}
    </div>
  );
}

export function InboxTrash(props: SessionChatP) {
  // const tLoc = location.split('/');
  const tabId = props.tabId;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { TrashedSessionData } = useTrashedStatus(
    props.workspacename,
    'Trash',
    props.selectedBotID,
    sortValue,
    pageNumber,
    () => setLoading(false)
  );
  const [loading, setLoading] = useState(false);
  const [trashSessionData, setTrashSessionData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && TrashedSessionData) {
      setTrashSessionData(TrashedSessionData.sessions.result);
      setTotalCount(TrashedSessionData.sessions.total_count);
    } else if (TrashedSessionData) {
      setTrashSessionData([
        ...trashSessionData,
        ...TrashedSessionData.sessions.result,
      ]);
      setTotalCount(TrashedSessionData.sessions.total_count);
    }
  }, [TrashedSessionData]);
  const [searchWord, setSearchWord] = useState<string>('');
  // const [convData, setConvData] = useState<Sessions>(initialSessionData);
  const queryClient = useQueryClient();
  const { pageState, setPageState, bots } = props;
  // const [allConvId, setAllConvId] = useState<string>('');
  // REVIEW :: Are we using this. Needs to be properly typed
  const sessionData =
    searchWord !== ''
      ? trashSessionData &&
        trashSessionData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : trashSessionData && trashSessionData;

  const idAvailable = sessionData
    ? props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convData = conversation(tabId, sessionData, idAvailable);

  useEffect(() => {
    setPageState({
      action: ActionT.DELETE,
      data: convData.id,
    });
  }, [convData]);
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      setPageState({
        action: ActionT.DELETE,
        data: convData.id,
      });
    }
  }, [pageState]);
  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };

  return (
    <div>
      {/* liveagent inobx trash */}
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <div></div>
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                  />
                  <div
                    className="arrow_up_form"
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('trashStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                 value={searchWord}
                  // className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      className={
                        convData.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user trash">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        {/* <span className="liveAt_user_radio_btn">
                        <label>
                          <input
                            type="radio"
                            className="option-input radio"
                            onClick={() => {
                              props.convoRestore(data.id);
                            }}
                            checked={
                              pageState.action === ActionT.DELETE &&
                              pageState.data === data.id
                            }
                            // onChange={() => {}}
                            // onChange={() => setPageState(VIEW)}
                          />
                        </label>
                      </span> */}
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.linkSelect(
                            props.selectedBotID,
                            data.chat_id,
                            'trash'
                          );
                          setPageState({
                            action: ActionT.DELETE,
                            data: data.id,
                          });
                          // setConvData(data);
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <div className="flex">
                          <p>Connected with:</p>
                          <span>
                            <div>
                              <UserIconAnalyticsMenu />
                            </div>
                            <h6>
                              {data.assigned_agent
                                ? data.assigned_agent.first_name +
                                  data.assigned_agent.last_name
                                : 'Unassigned'}
                            </h6>
                          </span>
                        </div>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          {/* <p>Closed</p> */}
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convData.id}
            workspace={props.workspacename}
            botName={botName}
            closeConvo={props.closeConvo}
          />
        </section>
      ) : (
        <section className="liveAgent_inbox_section trash empty">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div className="liveAt_inbox_title flex">
                <div></div>
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value="Newest"
                  />
                  <div className="arrow_up_form">
                    <DownArrowCircle />
                  </div>
                </div>
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                  value={searchWord}
                  // className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e)=> setSearchWord(e.target.value)}
                />
                <SearchIcon />
              </span>
            </div>
            <div className="liveAt_inbox_mail_list_container"></div>
          </div>
          <div className="liveAgent_inbox_conversation_container trash">
            <div className="liveAt_conversation_title flex"></div>
            <div className="liveAgent_inbox_conversation_box">
              <div className="liveAgent_inbox_conversation_trash">
                <p>No conversations to display</p>
              </div>
            </div>
          </div>
          <div className="liveAgent_inbox_userInfo"></div>
        </section>
      )}
    </div>
  );
}

export function EmptyContainer({
  searchWord,
  setSearchWord,
}: {
  searchWord: string;
  setSearchWord: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <section className="liveAgent_inbox_section empty">
      <div className="liveAgent_inbox_list">
        <div className="liveAt_inbox_title_container">
          <div className="liveAt_inbox_title flex">
            <input type="radio" disabled />
            <div className="liveAt_newest_dropdown">
              <Input
                className="liveAt_mail_filter"
                type="text"
                value="Newest"
              />
              <div className="arrow_up_form">
                <DownArrowCircle />
              </div>
            </div>
          </div>
        </div>
        <div className="liveAt_inbox_search emptyPage">
          <span className="search_click">
            <input
              value={searchWord}
              // className="search_click"
              type="search"
              placeholder="Search user"
              onChange={(e) => setSearchWord(e.target.value)}
            />
            <SearchIcon />
          </span>
        </div>
        <div className="liveAt_inbox_mail_list_container"></div>
      </div>
      <div className="liveAgent_inbox_conversation_container trash">
        <div className="liveAt_conversation_title flex"></div>
        <div className="liveAgent_inbox_conversation_box">
          <div className="liveAgent_inbox_conversation_trash">
            <p>No conversations to display</p>
          </div>
        </div>
      </div>
      <div className="liveAgent_inbox_userInfo"></div>
    </section>
  );
}
